.Footer {}

.social_list{
    margin-top: 20px;
    display: flex;
    padding-left: 0!important;
  }
  .social_list li{
    background-color: #00CC99;
    color: #FFF8F8;
    width: 67px;
    height: 57px;
    border-radius: 10px;
    font-size: 30px;
    margin-right: 15px;
    list-style: none;
    text-align: center;
  }
  .social_list li i{
    padding-top: 15px;
  }
  .social_list li:hover{
    background-color: #FB8F03;
    cursor: pointer;
  }
  .social_list li a{
    color:white
  }